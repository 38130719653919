<template>
<div>
<Header></Header>
    <!--顶部banner开始-->
    <div class="aboutus-banner left"> <img src="~images/banner/lianxiwomen.jpg" width="100%" /> </div>

    <!--内容开始-->
    <div class="contact-content width-bai left">
            <div class="contact-contents center">
            <!--面包屑开始-->
                <Breadcrumb>
                <a href="/index" title="首页"> <BreadcrumbItem>首页</BreadcrumbItem> </a>
                <BreadcrumbItem>联系我们</BreadcrumbItem>
                </Breadcrumb>

                <div style="height:20px;"></div>
                <div class="contact-text" v-html="data.content"> {{data.content}} </div>
            </div>
    </div>

    <div class="left clearfix width-bai"> <Footer></Footer> </div>
</div>
</template>
<script>
import Header from '../layout/Header.vue'
import Footer from '../layout/Footer.vue'
export default { 
   name:"",

   data() {
      return {
         data:'' 
      }
   },

   components:{
    Header,
    Footer
  },

mounted() {
  this.$axios.post(window.configs.svcProt + "://" + window.configs.svcIpPort + '/get_contact.html?token='+this.$store.state.token)
	.then(res=>{
    this.data=res.data.result;
  });
},

}
</script>

<style scoped>
    .contact-text{width:100%;min-height:800px;margin-top:20px;}
    .contact-contents{min-height:800px;width:1400px;margin-top:20px;font-size:16px;box-shadow: 0px 2px 5px #cccccc;border-radius:5px;padding:20px;}
    .contact-content{min-height:800px;}
</style>
